/* eslint-disable react/no-unescaped-entities */
import Constants from "Constants";
import React from "react";
import { motion } from "framer-motion";
import {
  Box,
  Button,
  Container,
  Heading,
  List,
  ListItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

function Pricing() {
  return (
    <Box bg="gray.100" id="pricing">
      <Container
        maxW={{ base: "container.sm", xl: "container.xl" }}
        py={{ base: "3rem", md: "6.5rem" }}
        id="pricing"
      >
        <VStack spacing={{ base: "3rem", md: "6rem" }}>
          {/* Heading */}
          <VStack
            spacing="1rem"
            alignItems={{ base: "start", md: "center" }}
            textAlign={{ base: "start", md: "center" }}
          >
            <Heading as="h1" size="h1">
            Pricing
            </Heading>
            <Text lineHeight="1.5">
            Publish your passion your way, whether you'd like to share your
            knowledge, experiences to the latest news.
            </Text>
          </VStack>
          {/* Grid */}
          <SimpleGrid
            as={motion.div}
            variants={Constants.cardsContainerVariant}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            maxW={{ base: "100%", lg: "100%" }}
            columns={{ base: 1, xl: 2 }}
            spacing={{ base: "1rem", md: "1rem", xl: "2rem" }}
          >
            <Box as={motion.div} variants={Constants.cardItemVariants} boxShadow="xl" p={6} borderRadius="lg" bg="white">
              <Text lineHeight="1.5" textAlign="center" fontWeight="semibold" color="gray.700">Free</Text>
              <Heading as="h1" size="h1" textAlign="center">
              $0
              </Heading>
              <List spacing={3} mt={8}>
                <ListItem>
                  <Text fontSize="sm" color="gray.600">
                  Free to use on single instance
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="sm" color="gray.600">
                  Support wide range of data connectors
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="sm" color="gray.600">
                  Unlimited users
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="sm" color="gray.600">
                  Unlimited data sources
                  </Text>
                </ListItem>
              </List>
              <Button
                w="100%"
                mt={20}
                bgColor="gray.900"
                color="white"
                _hover={{ bgColor: "black" }}
                onClick={()=>{
                  window.open("https://github.com/dataphion/guardiuum", "_blank");
                }}
              >
              Get Started
              </Button>
            </Box>
            <Box as={motion.div} variants={Constants.cardItemVariants} boxShadow="xl" p={6} borderRadius="lg"  bg="white">
              <Text lineHeight="1.5" textAlign="center" fontWeight="semibold" color="gray.700">Enterprise</Text>
              <Heading as="h1" size="h1" textAlign="center">
              Talk to us
              </Heading>
              <List spacing={3} mt={8}>
                <ListItem>
                  <Text fontSize="sm" color="gray.600">
                  All features as part of Free Tier +
Deployment on Kubernetes cluster
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="sm" color="gray.600">
                  High Availability
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="sm" color="gray.600">
                  Dedicated Customer Support
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="sm" color="gray.600">
                  Priority support for new data source connectors 
                  </Text>
                </ListItem>
              </List>
              <Button
                w="100%"
                mt={20}
                bgColor="gray.900"
                color="white"
                _hover={{ bgColor: "black" }}
                onClick={()=>{
                  document
                    .getElementById("contact-us")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
              Contact Us
              </Button>
            </Box>
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
}

export default Pricing;
