
const Constants={
  fadeUpVariants:{
    onscreen:{
      y:0,
      opacity:1,
      zIndex:1,
      transition:{
        duration:0.2
      }
    },
    offscreen:{
      y:50,
      opacity:0
    }
  },
  cardsContainerVariant: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  },
  cardItemVariants : {
    hidden: { opacity: 0, scale: 0.5 },
    show: {
      opacity: 1,
      scale: 1,
      zIndex: 1,
      transition: {
        duration: 1.4,
        type: "spring",
        damping: 5,
        stiffness: 70,
      },
    },
  }
};

export default Constants;