import React from "react";
import axios from "axios";

import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import Constants from "Constants";
import LogoBlack from "assets/images/brand-logo/guardiuum-black.svg";
import { motion } from "framer-motion";

function Footer() {
  const [inputValue, setInputValue] = React.useState("");
  const [errors, setErrors] = React.useState({
    name: "",
    company: "",
    email: "",
    message: "",
  });
  const toast = useToast();
  const handleChange = (event) => {
    setInputValue({...inputValue,[event.target.name]:event.target.value});
    validateField(event.target.name, event.target.value);
  };

  const validateField = (name, value) => {
    let error = "";
    if (!value.trim()) {
      error = "This field is required";
    } else if (name === "email" && !isEmailValid(value)) {
      error = "Invalid email address";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };


  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isFormValid = () => {
    const { name, company, email, message } = inputValue;
    return (
      name &&
      company &&
      email &&
      message &&
      isEmailValid(email) &&
      !errors.name &&
      !errors.company &&
      !errors.email &&
      !errors.message
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isFormValid()) return;

    const apiUrl = "https://tracker.dataphion.com/api/contact-uses";

    const payload = {
      data:{
        name: inputValue.name,
        email: inputValue.email,
        contact_number: inputValue.contact,
        company: inputValue.company,
        message: inputValue.message,
        source:"guardiuum"
      } 
    };

    try {
      const response = await axios.post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        toast({
          title: "Thank you for contacting us! We'll get back to you as soon as possible.",
          status: "success",
          position:"top",
          duration: 5000,
          isClosable: true,
        });
        setInputValue({
          name: "",
          company: "",
          email: "",
          contact: "",
          message: ""
        });
      } else {
        toast({
          title: "Something went wrong!",
          status: "error",
          position:"top",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Something went wrong!",
        status: "error",
        position:"top",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="gray.100" id="contact-us">
      <Container maxW={{ base: "container.sm", xl: "container.xl" }} py="5rem">
        <Stack
          direction={{ base: "column", xl: "row" }}
          alignItems="start"
          spacing="3.75rem"
        >
          <Stack direction={{ base: "column", sm: "row" }} spacing="3.75rem">
            {/* Logo & Links */}
            <Stack
              as={motion.div}
              variants={Constants.fadeUpVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{once:true,amount:0.6}}
              direction="column" spacing="1.5rem">
              <Box py="0.25rem">
                <LogoBlack />
              </Box>
              <Text>Data Security platform</Text>
            </Stack>
            {/* Contacts */}
            <Stack
              as={motion.div}
              variants={Constants.fadeUpVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{once:true,amount:0.6}} direction="column" spacing="1.5rem">
              <Heading as="h3" size="h3">
                Contact info
              </Heading>
              <Stack direction="row" spacing="0.5rem">
                <Box color="blue.400" fontSize="1.2rem" minW={6} display="flex" justifyContent="center" mt={2}>
                  <i className="fa-solid fa-location-dot"/>
                </Box>
                <Text>Sri Shanthi Towers I, 2nd Main East Of NGEF Layout Kasturi Nagar Bangalore - 560043</Text>
              </Stack>
              <Stack direction="row" spacing="0.5rem">
                <Box color="blue.400" fontSize="1.2rem" minW={6} display="flex" justifyContent="center" mt={2}>
                  <i className="fa-solid fa-location-dot"/>
                </Box>
                <Text>137 E Carob Drive, Chandler, Arizona, USA - 85286</Text>
              </Stack>
              <Stack direction="row" spacing="0.5rem">
                <Box color="blue.400" fontSize="1.2rem" minW={6} display="flex" justifyContent="center" mt={2}>
                  <i className="fa-solid fa-location-dot"/>
                </Box>
                <Text>F9, Shivan Avenue, Near Platinum Park, Causeway Link Road, Katargam, Surat - 395004</Text>
              </Stack>
              <Stack direction="row" spacing="0.5rem">
                <Box color="blue.400" fontSize="1.2rem" minW={6} display="flex" justifyContent="center" alignItems="center">
                  <i className="fa-solid fa-envelope"/>
                </Box>
                <Text>guardiuum@dataphion.com</Text>
              </Stack>
            </Stack>
          </Stack>
          {/* Email form */}
          <Stack
            as={motion.div}
            variants={Constants.fadeUpVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{once:true,amount:0.6}}
            direction="column"
            spacing="1.5rem"
            w="100%"
            maxW={{ base: "auto", xl: "517px" }}
          >
            <Heading as="h3" size="h3">
              Get in touch
            </Heading>
            <FormControl isInvalid={!!errors.name}>
              <Input
                placeholder="Your name"
                bg="white"
                name="name"
                value={inputValue.name}
                onChange={handleChange}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.company}>
              <Input
                placeholder="Company"
                bg="white"
                name="company"
                value={inputValue.company}
                onChange={handleChange}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
              />
              <FormErrorMessage>{errors.company}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.email}>
              <Input
                placeholder="Your email"
                bg="white"
                name="email"
                value={inputValue.email}
                onChange={handleChange}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            <Input type="number" placeholder="Contact Number (Optional)" bg="white" name="contact" value={inputValue?.contact} onChange={handleChange} />
            <FormControl isInvalid={!!errors.message}>
              <Textarea
                placeholder="Your message"
                bg="white"
                resize="none"
                name="message"
                value={inputValue.message}
                onChange={handleChange}
                onBlur={(e) => validateField(e.target.name, e.target.value)}
              />
              <FormErrorMessage>{errors.message}</FormErrorMessage>
            </FormControl>
            <Box>
              <Button colorScheme="blue" disabled={!isFormValid()} onClick={handleSubmit}>Send</Button>
            </Box>
          </Stack>
        </Stack>
      </Container>
      <Divider borderColor="gray.400" />
      <Container maxW="container.xl" py="1.25rem" display="flex" justifyContent="space-between">
      ©2024 Dataphion. All rights reserved.

        <Text>
          <a href="https://dataphion.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> |{" "}
          <a href="https://dataphion.com/privacy-policy"  target="_blank" rel="noreferrer">Terms and Conditions</a> |{" "}
          <a href="https://dataphion.com/privacy-policy"  target="_blank" rel="noreferrer">Cookie Policy</a> 
        </Text>
      </Container>
     

    </Box>
  );
}

export default Footer;
