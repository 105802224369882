import React from "react";

import Constants from "Constants";
import { motion } from "framer-motion";
import { Box, Container, Heading, Image, SimpleGrid, Text, VStack } from "@chakra-ui/react";

function FeatureImages() {
  const useCaseData = [
    {
      heading: "Query Federation",
      description: "Seamlessly integrate and query data from multiple sources in real-time for comprehensive insights.",
      icon:"assets/images/hero/query-federation.svg"
    },
    {
      heading: "Enforce Data Access Policy While Accessing",
      description: "Ensure compliance and security by enforcing data access policies during data retrieval.",
      icon:"assets/images/hero/data-access-policy.svg"

    },
    {
      heading: "Connect Object Storage such as S3 bucket, ABFS, MinIO etc., with Databricks Unity Catalog",
      description: "Easily link your S3 buckets to Databricks Unity Catalog for streamlined data management and analysis.",
      icon:"assets/images/hero/s3-bucket.svg"

    },
    {
      heading: "Connect Hive Server to Databricks Unity Catalog",
      description: "Integrate your Hive server with Databricks Unity Catalog to centralize and optimize your data workflows.",
      icon:"assets/images/hero/hive-server.svg"

    }
  ];
  
  
  return (
    <Box bg="gray.900" id="use-case">
      <Container
        maxW={{ base: "container.sm", xl: "container.xl" }}
        py={{ base: "3rem", md: "6.5rem" }}
      >
        <VStack
          spacing="1rem"
          alignItems={{ base: "start", md: "center" }}
          textAlign={{ base: "start", md: "center" }}
          pb={{ base: "3rem", md: "6.5rem" }}
        >
          <Heading as="h1"
            size="h1"
            color="white"
            textAlign={{ base: "start", md: "center" }}>
            Use Cases
          </Heading>
          <Text lineHeight="1.5" color="white">
          Discover how Guardiuum features can be applied to real world scenarios
          </Text>
        </VStack>
        <Box maxW={{ base: "100%" }}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing="4rem">
            {useCaseData?.map((item, index) => (
              <VStack
                as={motion.div}
                variants={Constants.fadeUpVariants}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once:true,amount:0.6}}key={index} alignItems="start" spacing="1rem">
                <Image
                  width={14}
                  height={14}
                  src={item?.icon}
                  alt="Hero image"
                  border="1px"
                  borderRadius="md"
                  borderColor="#FF7E46"
                  padding={1.5}
                />
                <Heading as="h3" size="lg" color="white">
                  {item?.heading}
                </Heading>
                <Text color="white" lineHeight="1.5">
                  {item?.description}
                </Text>
              </VStack>
            ))}
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
}

export default FeatureImages;
