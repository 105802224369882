import LogoWhite from "assets/images/brand-logo/guardiuum-white.svg";
import MobileNav from "./MobileNav";
import React from "react";
import { Box, Container, HStack, Link, Show } from "@chakra-ui/react";

function NavBar() {
  const navLinks = [
    {
      name: "Features",
      href: "#features",
    },
    {
      name: "Documentation",
      href: "https://dataphion.github.io/guardiuum/#/",
    },
    {
      name: "Use Cases",
      href: "#use-case",
    },
    {
      name: "Pricing",
      href: "#pricing",
    },
    {
      name: "FAQs",
      href: "#faqs",
    },
    {
      name: "Contact Us",
      href: "#contact-us",
    },
  ];

  return (
    <Box bg="gray.900" position="sticky" top={0} zIndex={99}>
      <Container maxW="container.xl" py="1.5rem">
        <HStack justifyContent="space-between" spacing="6rem">
          {/* Left links */}
          <HStack spacing="6rem">
            <Link
              href="#home"
              onClick={(e) => {
                e.preventDefault();
                const href = "#home";
                document
                  .getElementById(href.substring(1))
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              <LogoWhite />
            </Link>
            <Show above="lg">
              <HStack spacing="3.50rem">
                {navLinks.map((link) => (
                  <Link
                    key={link.href}
                    href={link.href}
                    colorScheme="whiteAlpha"
                    color="white"
                    _hover={{ textDecoration: "none" }}
                    onClick={(e) => {
                      e.preventDefault();
                      if(link.name != "Documentation"){

                        document
                          .getElementById(link.href.substring(1))
                          .scrollIntoView({ behavior: "smooth" });
                      } else {
                        window.open(link?.href, "_blank");
                      }
                    }}
                  >
                    {link.name}
                  </Link>
                ))}
              </HStack>
            </Show>
          </HStack>
          <MobileNav links={navLinks} />
        </HStack>
      </Container>
    </Box>
  );
}

export default NavBar;
