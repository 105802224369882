import React from "react";
import { Container, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react";

import Constants from "Constants";
import FeatureCell from "./FeatureCell";
import { motion } from "framer-motion";

const features = [
  {
    icon: <i className="fa-solid fa-database"/>,
    heading: "Centralised data management",
    text: "Guardiumm will be the entry point for all data sources provisioned in the platform.",
  },
  {
    icon: <i className="fa-regular fa-file-code"/>,
    heading: "SQL Everywhere",
    text: "Any connected platform can be connected using Postgres compliant client and access data using SQL queries.",
  },
  {
    icon: <i className="fa-solid fa-code"/>,
    heading: "Query Federation",
    text: "Run queries directly on the database without moving the data across. Queries can be written to perform complicated join across various different data sources.",
  },
  {
    icon: <i className="fa-regular fa-file-lines"/>,
    heading: "Audit Logging",
    text: "Captures every interation within the platform and generate insights into the data is being accessed.",
  },
  {
    icon: <i className="fa-solid fa-user-shield"/>,
    heading: "Role based Security policy",
    text: "Apply individual level or group based security policy for accessing the data.",
  },
  {
    icon: <i className="fa-solid fa-building-shield"/>,
    heading: "Enterprise auth integration",
    text: "Support for simple, Kerberose(Upcoming) and Active Directory and Azure Entra ID authentication.",
  },
  {
    icon: <i className="fa-regular fa-copy"/>,
    heading: "Zero Data Copy",
    text: "With in-built query federation, data is not moved between platforms instead, it is directly queried against the source platform where data resides. For end user Guardiuum is the data provider but in the backend Guardiuum identifies the right source, executes the query on the right platform and returns the results back to the users. This will be very helpful in building a unified analytics dashboard, exporting enriched data for consumers etc.",
  },
  {
    icon: <i className="fa-brands fa-phabricator"/>,
    heading: "Inherently Support Data Mesh and Data Fabric",
    text: "Guardiuum plays a crucial role in advancing the capabilities of both data mesh and data fabric architectures, offering tools that facilitate better data governance and seamless interconnectivity. By integrating with Guardiuum, organizations can leverage their distributed data assets more efficiently and securely.",
  },
];

function FeatureGrid() {
  return (
    <Container
      maxW={{ base: "container.sm", xl: "container.xl" }}
      py={{ base: "3rem", md: "6.5rem" }}
      id="features"
    >
      <VStack spacing={{ base: "3rem", md: "7.5rem" }}>
        {/* Heading */}
        <VStack
          spacing="1rem"
          alignItems={{ base: "start", md: "center" }}
          textAlign={{ base: "start", md: "center" }}
        >
          <Heading as="h1" size="h1">
            Features
          </Heading>
          <Text lineHeight="1.5">
          Explore the features that makes Guardiuum unique, effection and powerful
          </Text>
        </VStack>
        {/* Grid */}
        <SimpleGrid
          columns={{ base: 1, md: 2, xl: 2 }}
          spacing={{ base: "3rem", md: "5rem", xl: "5rem" }}
          maxW="container.xl"
        >
          {features.map((feature, index) => (
            <FeatureCell 
              as={motion.div}
              variants={Constants.fadeUpVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{once:true,amount:0.6}}
              key={`feature-${index}`} {...feature} />
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  );
}

export default FeatureGrid;
