import React from "react";

import Constants from "Constants";
import { motion } from "framer-motion";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Container, Heading, Text, VStack } from "@chakra-ui/react";


function Gallery() {

  const guardiuumFAQ = [
    {
      question: "What is Guardiuum?",
      answer: "Guardiuum is a centralized Data Governance solution, supporting on-premise as well as cloud-agnostic environments. In the era of data-driven decision-making, Guardiuum emerges as a pivotal tool for enterprises aiming to harness the power of their data securely and efficiently. Guardiuum is a comprehensive data governance platform that serves as the backbone for managing your organization's data landscape. With its robust connectors, Guardiuum integrates seamlessly with various platforms, offering a unified view of schemas, namespaces, tables, and columns."
    },
    {
      question: "How does Guardiuum enhance data security?",
      answer: "Guardiuum enhances security through robust policy enforcement mechanisms that are automatically applied as users interact with the databases. It supports comprehensive governance, supporting databases, object storage, and enterprise tools (e.g., Salesforce)."
    },
    {
      question: "Can Guardiuum integrate with existing data management systems?",
      answer: "Yes, Guardiuum is designed to seamlessly integrate with a wide range of data sources, including traditional databases and modern data lakes stored in cloud-based object storage. This flexibility helps maintain consistency in policy enforcement across diverse data environments."
    },
    {
      question: "What makes Guardiuum suitable for both data mesh and data fabric architectures?",
      answer: "Guardiuum supports decentralized data management, making it ideal for data mesh architectures by enabling domain-specific governance. It also provides the seamless connectivity and policy automation required for data fabric architectures, facilitating a unified view and management of data assets."
    },
    {
      question: "How does Guardiuum handle large-scale data environments?",
      answer: "Guardiuum is built to scale with your data needs. It can manage large volumes of data across multiple storage systems without compromising on performance or security. Its governance capabilities are designed to handle complex and growing data environments efficiently."
    },
    {
      question: "Is there a trial version of Guardiuum available?",
      answer: "For those interested in experiencing Guardiuum’s capabilities, we offer a free-to-use version. Please contact our sales team for more information and to get started with your setup."
    },
    {
      question: "What support options are available for Guardiuum users?",
      answer: "We provide comprehensive support for all our users, including a dedicated help desk and detailed documentation."
    },
    {
      question: "How does Guardiuum ensure compliance with data privacy regulations?",
      answer: "Guardiuum is designed to help organizations comply with various data privacy laws and regulations. By automating data governance and enforcing predefined policies, it ensures that all data handling within the system adheres to the necessary legal frameworks."
    }
  ];
  

  return (
    <Container
      maxW={{ base: "container.sm", xl: "container.xl" }}
      py={{ base: "3rem", md: "6.5rem" }}
      id="faqs"
    >
      <VStack spacing={{ base: "3rem" }} width="100%">
        <VStack spacing="1rem" width={{base:"100%", md:"100%", xl:"60%" }}>
          <Heading as="h1" size="h1" width="100%" textAlign="center">
            Frequently Asked Questions About Guardiuum
          </Heading>
          {/* <Text>Watch your favorite content on our platform</Text> */}
        </VStack>
        <Accordion width="100%" allowToggle>
          {guardiuumFAQ?.map((item, index) => (
            <AccordionItem
              as={motion.div}
              variants={Constants.fadeUpVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{once:true,amount:0.6}}
              key={index}
            >
              <h2>
                <AccordionButton paddingY={4}>
                  <Box as="span" flex="1" textAlign="left" fontWeight="bold" fontSize="lg">
                    {item?.question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} marginY={2}>
                <Text color="gray.600" fontWeight="semibold" fontSize="lg">
                  {item?.answer}
                </Text>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </VStack>
    </Container>
  );
}

export default Gallery;
