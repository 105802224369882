import Constants from "Constants";
import React from "react";
import { motion } from "framer-motion";

import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

function Hero() {
  return (
    <Box bg="gray.900" id="home" backgroundImage={{md:"linear-gradient(to right, black , transparent), url('assets/images/background.jpg')", xl:"linear-gradient(to right, black , transparent), url('assets/images/background.jpg')", base:"bg-black"}} backgroundSize="cover" backgroundPosition="center">
      <Container
        maxW={{ base: "container.sm", xl: "container.xl" }}
        pt={{ base: "3rem", md: "4.75rem" }}
        pb={{ base: "3rem", xl: "6.25rem" }}
        
      >
        <Stack
          direction={{ base: "column", xl: "row", md:"row" }}
          justifyContent={{ base: "start", md: "space-between", xl: "space-between" }}
          alignItems={{ base: "center", xl: "center", md:"center" }}
          spacing={{ base: "3rem", xl: "7rem", md:"7rem" }}
        >
          {/* Text */}
          <VStack
            as={motion.div}
            variants={Constants.fadeUpVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{once:true,amount:0.6}}
            alignItems="start"
            textAlign="start"
            spacing="3rem"
            w={{xl:"50%", base:"100%", md:"50%"}}
          >
            <VStack spacing="1rem">
              <Heading as="h1" size="h1" color="white">
                Enterprise Data Security at the Speed of Now
              </Heading>
              <Text color="white" lineHeight="1.5">
              Secure your data instantly with Guardiuum. Experience seamless integration, robust protection, and real-time monitoring. Guardiuum – where innovation meets reliability.
              </Text>
            </VStack>
          </VStack>
         
        </Stack>
      </Container>
    </Box>
  );
}

export default Hero;
