import FeaturesBig from "components/FeatureImages";
import FeaturesGrid from "components/FeatureGrid";
import Footer from "components/Footer";
import Gallery from "components/Gallery";
import Hero from "components/Hero";
import NavBar from "components/NavBar";
import Pricing from "components/Pricing";
import React from "react";

export default function App() {
  React.useEffect(() => {
    window.scrollTo({
      top: document.getElementById("home").offsetTop,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <NavBar />
      <Hero />
      <FeaturesGrid />
      <FeaturesBig />
      <Pricing />
      <Gallery />
      <Footer />
    </>
  );
}
